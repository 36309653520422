<template>
  <div class="view">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'companyVehicle', params: {} }"
      >
        Company Vehicle
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'companyVehicleView', params: {} }">
        View Company Vehicle
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="loaded">
      <v-card-title> View Company Vehicle </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-if="companyVehicle.vehicleLicensePlate">
                <td max-width="35%" class="text-subtitle-1">License Plate:</td>
                <td>{{ companyVehicle.vehicleLicensePlate }}</td>
              </tr>

              <tr v-if="companyVehicle.vehicleType">
                <td max-width="35%" class="text-subtitle-1">Type:</td>
                <td>{{ companyVehicle.vehicleType }}</td>
              </tr>

              <tr v-if="companyVehicle.vehicleNotes">
                <td max-width="35%" class="text-subtitle-1">Notes:</td>
                <td>{{ companyVehicle.vehicleNotes }}</td>
              </tr>

              <tr v-if="companyVehicle.company">
                <td max-width="35%" class="text-subtitle-1">Company:</td>
                <td>
                  {{
                    getRelationText(companyVehicle.company, "company", "name")
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-space-between ma-5">
      <v-btn @click="goBack">Back</v-btn>
      <v-btn @click="edit(id)" color="primary">Edit</v-btn>
      <span></span>
    </div>
  </div>
</template>

<script>
import { getCompanyVehicle } from "@/services/companyVehicle";

import { getAllCompanies } from "@/services/company";

export default {
  data() {
    return {
      id: "",
      companyVehicle: {},
      loaded: false,
      relations: {},
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    edit(id) {
      this.$router.push({ name: "companyVehicleEdit", params: { id: id } });
    },
    setInstance() {
      this.setDependencies().then(() => {
        getCompanyVehicle(this.id)
          .then((response) => {
            this.companyVehicle = response.data;
            this.loaded = true;
          })
          .catch(() => {
            this.addErrorMessage("Error loading companyVehicle");
            this.loaded = true;
          });
      });
    },
    async setDependencies() {
      const params_company = {
        page: 0,
        size: 100,
      };
      await getAllCompanies(params_company).then((response) => {
        this.relations.company = response.data.content;
        return this.relations.company;
      });
    },
    getRelationText(id, relationProperty, relationAttribute) {
      const relationList = this.relations[relationProperty];
      if (relationList) {
        const relationObj = relationList.find((r) => r.id == id);
        return relationObj[relationAttribute];
      } else {
        return "";
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.setInstance();
    this.$emit("showParent", false);
  },
};
</script>
